import * as React from 'react'
import {graphql} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

export const query = graphql`
    query image($id: String) {
        imageSharp(id: { eq: $id}) {
            gatsbyImageData
            id
        }
    }`

const FullScreenImage = ({data}) => {
    return (
            <GatsbyImage className="static" objectFit="contain" alt="Imagefullscreen" image={data.imageSharp.gatsbyImageData}/>
    )
}
export default FullScreenImage

